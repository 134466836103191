@import url("https://fonts.googleapis.com/css?family=Fira+Mono");
body, .body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: "Fira Mono Bold", monospace;
  background: #ddd3e6;
  color: #21013d; }

.header {
  margin: 20% 20% 5% 20%;
  height: 50vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-family: "Fira Mono Bold", monospace;
  background: #ddd3e6;
  color: #21013d; }

a {
  color: #21013d; }

a:hover {
  text-decoration: none;
  background-color: #21013d;
  color: #ddd3e6; }

.h1 {
  font-family: "Fira Mono Bold", monospace;
  color: #21013d;
  font-size: 40px;
  padding: 20px; }

.text-input {
  font-family: "Fira Mono Bold", monospace;
  position: relative;
  margin-top: 50px; }
  .text-input input[type="text"] {
    font-family: "Fira Mono Bold", monospace;
    display: inline-block;
    width: 500px;
    height: 50px;
    box-sizing: border-box;
    outline: none;
    border: 1px solid lightgray;
    border-radius: 3px;
    padding: 10px 10px 10px 10px;
    transition: all 0.1s ease-out; }
  .text-input input[type="text"] + label {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 50px;
    line-height: 50px;
    color: white;
    border-radius: 3px 0 0 3px;
    padding: 0 25px;
    background: #21013d;
    transform: translateZ(0) translateX(0);
    transition: all 0.3s ease-in;
    transition-delay: 0.2s; }
  .text-input input[type="text"]:focus + label {
    transform: translateY(-120%) translateX(0%);
    border-radius: 3px;
    transition: all 0.1s ease-out; }
  .text-input input[type="text"]:focus {
    padding: 10px;
    transition: all 0.3s ease-out;
    transition-delay: 0.2s; }

.cell {
  border: #21013d solid;
  border-width: 3px;
  font-size: 40px;
  padding: 5px;
  margin: 7px;
  width: 10%;
  display: flex;
  justify-content: center; }

.cell:hover {
  background: #21013d;
  color: #ddd3e6;
  cursor: pointer; }

.row {
  padding: 10px;
  margin: 2px;
  display: flex;
  flex-direction: row; }

.popup {
  width: 50%;
  background-color: #ddd3e6;
  opacity: 70%;
  height: 50%;
  position: absolute;
  z-index: 10;
  visibility: visible; }

.popup .show {
  visibility: visible; }

.popup-content {
  margin: auto;
  background: white;
  width: 50%;
  padding: 5px; }

.popup-arrow {
  color: white; }

[role='tooltip'].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px; }

.popup-overlay {
  background: rgba(0, 0, 0, 0.5); }

[data-popup='tooltip'].popup-overlay {
  background: transparent; }

@media screen and (max-width: 45em) {
  ::-webkit-scrollbar {
    
    display: none; } 
    .header {
      margin: 10% 20% 5% 20%;
      height: 40vh;}
  
    .cell {
      border: #21013d solid;
      border-width: 2px;
      font-size: 20px;
      padding: 3px;
      margin: 3px;
      width: 10%;
      display: flex;
      justify-content: center; }
    }

